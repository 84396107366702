export const diseases = [
  'Healthy Adults',
  'Atrial fibrillation (A fib)',
  'Abdominal aortic aneurysm',
  'Hyperhidrosis',
  "Bartholin's cyst",
  'Absence seizure',
  'Acanthosis nigricans',
  'Achalasia',
  'Achilles tendinitis',
  'Achilles tendon rupture',
  'ACL injury',
  'Acne',
  'Acoustic neuroma',
  'Acromegaly',
  'Actinic keratosis',
  'Acute coronary syndrome',
  'Acute flaccid myelitis (AFM)',
  'Acute kidney failure',
  'Acute liver failure',
  'Acute lymphocytic leukemia',
  'Acute myelogenous leukemia',
  'Acute sinusitis',
  'Addiction',
  "Addison's disease",
  'Adenomyosis',
  'Adjustment disorders',
  'Adnexal tumors',
  'Adrenal cancer',
  'Adrenoleukodystrophy',
  'Adult attention-deficit/hyperactivity disorder (ADHD)',
  "Adult Still's disease",
  'Age spots (liver spots)',
  'Agoraphobia',
  'Airplane ear',
  'Albinism',
  'Alcohol intolerance',
  'Alcohol poisoning',
  'Alcohol use disorder',
  'Alcoholic hepatitis',
  'Allergies',
  'Alpha-gal syndrome',
  "Alzheimer's disease",
  'Ambiguous genitalia',
  'Ameloblastoma',
  'Amenorrhea',
  'Amnesia',
  'Amniotic fluid embolism',
  'Ampullary cancer',
  'Amyloidosis',
  'Amyotrophic lateral sclerosis (ALS)',
  'Anal cancer',
  'Anal fissure',
  'Anal fistula',
  'Anal itching',
  'Anaphylaxis',
  'Anemia',
  'Aneurysms',
  'Angelman syndrome',
  'Angina',
  'Angiosarcoma',
  'Anhidrosis',
  'Ankylosing spondylitis',
  'Anorexia nervosa',
  'Anorgasmia in women',
  'Anterior prolapse (cystocele)',
  'Anthrax',
  'Antibiotic-associated diarrhea',
  'Antiphospholipid syndrome',
  'Antisocial personality disorder',
  'Anxiety disorders',
  'Aortic aneurysm',
  'Aortic dissection',
  'Aortic valve disease',
  'Aortic valve regurgitation',
  'Aortic valve stenosis',
  'Aphasia',
  'Aplastic anemia',
  'Appendicitis',
  'ARDS',
  'Arteriosclerosis / atherosclerosis',
  'Arteriovenous fistula',
  'Arteriovenous malformation',
  'Arthritis',
  'Asbestosis',
  'Ascariasis',
  'Aspergillosis',
  'Asthma',
  'Asthma attack',
  'Astigmatism',
  'Astrocytoma',
  'Ataxia',
  'Atelectasis',
  "Athlete's foot",
  'Atopic dermatitis (eczema)',
  'Atrial fibrillation',
  'Atrial flutter',
  'Atrial septal defect (ASD)',
  'Atrial tachycardia',
  'Atrioventricular canal defect',
  'Atrioventricular nodal reentry tachycardia (AVNRT)',
  'Attention-deficit/hyperactivity disorder (ADHD) in children',
  'Atypical depression',
  'Atypical hyperplasia of the breast',
  'Autism spectrum disorder',
  'Autoimmune hepatitis',
  'Autoimmune pancreatitis',
  'Autonomic neuropathy',
  'Avascular necrosis',
  'Baby acne',
  'Back pain',
  'Bacterial vaginosis',
  'Bad breath',
  'Bags under eyes',
  "Baker's cyst",
  'Balance problems',
  "Barrett's esophagus",
  "Bartholin's cyst",
  'Basal cell carcinoma',
  'Bedbugs',
  'Bedsores (pressure ulcers)',
  'Bed-wetting',
  'Bee sting',
  "Behcet's disease",
  "Bell's palsy",
  'Benign adrenal tumors',
  'Benign paroxysmal positional vertigo (BPPV)',
  'Benign peripheral nerve tumor',
  'Benign prostatic hyperplasia (BPH)',
  'Bicuspid aortic valve',
  'Bile reflux',
  'Binge-eating disorder',
  'Bipolar disorder',
  'Bird flu (avian influenza)',
  'Black hairy tongue',
  'Bladder cancer',
  'Bladder exstrophy',
  'Bladder stones',
  'Blastocystis hominis',
  'Blepharitis',
  'Blocked tear duct',
  'Blood in urine (hematuria)',
  'Body dysmorphic disorder',
  'Body lice',
  'Boils and carbuncles',
  'Bone cancer',
  'Bone metastasis',
  'Bone spurs',
  'Borderline personality disorder',
  'Botulism',
  'Brachial plexus injury',
  'Bradycardia',
  'Brain aneurysm',
  'Brain AVM (arteriovenous malformation)',
  'Brain metastases',
  'Brain tumor',
  'Breast cancer',
  'Breast cysts',
  'Breast pain',
  'Broken ankle',
  'Broken arm',
  'Broken collarbone',
  'Broken foot',
  'Broken hand',
  'Broken heart syndrome',
  'Broken leg',
  'Broken nose',
  'Broken ribs',
  'Broken toe',
  'Broken wrist',
  'Bronchiolitis',
  'Bronchitis',
  'Brucellosis',
  'Brugada syndrome',
  'Bruxism (teeth grinding)',
  "Buerger's disease",
  'Bulimia nervosa',
  'Bullous pemphigoid',
  'Bundle branch block',
  'Bunions',
  'Burning mouth syndrome',
  'Burns',
  'Bursitis',
  'C. difficile infection',
  'Calciphylaxis',
  'Calluses and corns',
  'Cancer',
  'Canker sore',
  'Carbon monoxide poisoning',
  'Carcinoid syndrome',
  'Carcinoid tumors',
  'Carcinoma of unknown primary',
  'Cardiogenic shock',
  'Cardiomyopathy',
  'Carotid artery disease',
  'Carpal tunnel syndrome',
  'Castleman disease',
  'Cataracts',
  'Cavernous malformations',
  'Cavities/tooth decay',
  'Celiac disease',
  'Cellulite',
  'Cellulitis',
  'Central nervous system vascular malformations',
  'Central sleep apnea',
  'Cerebral palsy',
  'Cervical cancer',
  'Cervical dystonia',
  'Cervical spondylosis',
  'Cervicitis',
  'Chagas disease',
  'Charcot-Marie-Tooth disease',
  'Chemo brain',
  'Chest pain',
  'Chiari malformation',
  'Chickenpox',
  'Chilblains',
  'Child abuse',
  'Childhood apraxia of speech',
  'Childhood asthma',
  'Childhood obesity',
  'Childhood schizophrenia',
  'Chlamydia trachomatis',
  'Cholangiocarcinoma (bile duct cancer)',
  'Cholecystitis',
  'Cholera',
  'Cholestasis of pregnancy',
  'Chondrosarcoma',
  'Chordoma',
  'Choroid plexus carcinoma',
  'Chronic cough',
  'Chronic daily headaches',
  'Chronic exertional compartment syndrome',
  'Chronic fatigue syndrome',
  'Chronic granulomatous disease',
  'Chronic hives',
  'Chronic kidney disease',
  'Chronic lymphocytic leukemia',
  'Chronic myelogenous leukemia',
  'Chronic pelvic pain in women',
  'Chronic sinusitis',
  'Chronic traumatic encephalopathy',
  'Churg-Strauss syndrome',
  'Cirrhosis',
  'Claudication',
  'Cleft lip and cleft palate',
  'Clubfoot',
  'Cluster headache',
  'Coarctation of the aorta',
  'Cold sore',
  'Cold urticaria',
  'Colic',
  'Colon cancer',
  'Colon polyps',
  'Color blindness',
  'Coma',
  'Common cold',
  'Common cold in babies',
  'Common variable immunodeficiency',
  'Common warts',
  'Complex regional pain syndrome',
  'Complicated grief',
  'Compulsive gambling',
  'Compulsive sexual behavior',
  'Concussion',
  'Congenital adrenal hyperplasia',
  'Congenital heart defects in children',
  'Congenital heart disease in adults',
  'Congenital mitral valve anomalies',
  'Congenital myasthenic syndromes',
  'Congenital myopathies',
  'Conjoined twins',
  'Constipation',
  'Constipation in children',
  'Contact dermatitis',
  'Convergence insufficiency',
  'COPD',
  'Corns and calluses',
  'Coronary artery disease',
  'Coronavirus disease 2019 (COVID-19)',
  'Corticobasal degeneration',
  'Costochondritis',
  'Cough headaches',
  'Cradle cap',
  'Craniopharyngioma',
  'Craniosynostosis',
  'Creutzfeldt-Jakob disease',
  "Crohn's disease",
  'Croup',
  'Cryoglobulinemia',
  'Cryptosporidium infection',
  'Cushing syndrome',
  'Cutaneous B-cell lymphoma',
  'Cutaneous T-cell lymphoma',
  'Cyclic vomiting syndrome',
  'Cyclospora infection',
  'Cyclothymia (cyclothymic disorder)',
  'Cystic fibrosis',
  'Cystitis',
  'Cytomegalovirus (CMV) infection',
  'Dandruff',
  "De Quervain's tenosynovitis",
  'Deep vein thrombosis (DVT)',
  'Dehydration',
  'Delayed ejaculation',
  'Delayed sleep phase',
  'Delirium',
  'Dementia',
  'Dengue fever',
  'Depersonalization-derealization disorder',
  'Depression (major depressive disorder)',
  'Dermatitis',
  'Dermatofibrosarcoma protuberans',
  'Dermatographia',
  'Dermatomyositis',
  'Desmoid tumors',
  'Desmoplastic small round cell tumors',
  'Deviated septum',
  'Diabetes',
  'Diabetes insipidus',
  'Diabetic coma',
  'Diabetic hyperosmolar syndrome',
  'Diabetic hypoglycemia',
  'Diabetic ketoacidosis',
  'Diabetic nephropathy',
  'Diabetic neuropathy',
  'Diabetic retinopathy',
  'Diaper rash',
  'Diarrhea',
  'Diffuse idiopathic skeletal hyperostosis (DISH)',
  'DiGeorge syndrome (22q11.2 deletion syndrome)',
  'Dilated cardiomyopathy',
  'Diphtheria',
  'Dislocated elbow',
  'Dislocated shoulder',
  'Dislocation',
  'Dissociative disorders',
  'Diverticulitis',
  'Dizziness',
  'Double uterus',
  'Double-outlet right ventricle',
  'Down syndrome',
  'Dressler syndrome',
  'Drug addiction (substance use disorder)',
  'Drug allergy',
  'Dry eyes',
  'Dry macular degeneration',
  'Dry mouth',
  'Dry skin',
  'Dry socket',
  'Ductal carcinoma in situ (DCIS)',
  'Dumping syndrome',
  "Dupuytren's contracture",
  'Dural arteriovenous fistulas',
  'Dust mite allergy',
  'Dwarfism',
  'Dysarthria',
  'Dyshidrosis',
  'Dyslexia',
  'Dysphagia',
  'Dystonia',
  'E. coli',
  'Ear infection (middle ear)',
  'Earwax blockage',
  'Eating disorders',
  'Ebola virus and Marburg virus',
  'Ebstein anomaly',
  'Ectopic pregnancy',
  'Ectropion',
  'Edema',
  'Egg allergy',
  'Ehlers-Danlos syndrome',
  'Ehrlichiosis and anaplasmosis',
  'Eisenmenger syndrome',
  'Elevated blood pressure',
  'Embryonal tumors',
  'Emphysema',
  'Encephalitis',
  'Encopresis',
  'Endocarditis',
  'Endometrial cancer',
  'Endometriosis',
  'End-stage renal disease',
  'Enlarged breasts in men (gynecomastia)',
  'Enlarged heart',
  'Enlarged liver',
  'Enlarged spleen (splenomegaly)',
  'Entropion',
  'Eosinophilic esophagitis',
  'Ependymoma',
  'Epidermoid cysts',
  'Epidermolysis bullosa',
  'Epididymitis',
  'Epiglottitis',
  'Epilepsy',
  'Epithelioid sarcoma',
  'Erectile dysfunction',
  'Esophageal cancer',
  'Esophageal spasms',
  'Esophageal varices',
  'Esophagitis',
  'Essential thrombocythemia',
  'Essential tremor',
  'Esthesioneuroblastoma',
  'Ewing sarcoma',
  'Exercise headaches',
  'Exercise-induced asthma',
  'External compression headaches',
  'Eye floaters',
  'Eye melanoma',
  'Eyestrain',
  'Factitious disorder',
  'Factor V Leiden',
  'Familial adenomatous polyposis',
  'Familial hypercholesterolemia',
  'Familial Mediterranean fever',
  'Farsightedness',
  'Febrile seizure',
  'Fecal incontinence',
  'Female infertility',
  'Female sexual dysfunction',
  'Fetal alcohol syndrome',
  'Fetal macrosomia',
  'Fever',
  'Fibroadenoma',
  'Fibrocystic breasts',
  'Fibromuscular dysplasia',
  'Fibromyalgia',
  'Fibrous dysplasia',
  'Flatfeet',
  'Floor of the mouth cancer',
  'Focal segmental glomerulosclerosis (FSGS)',
  'Folliculitis',
  'Food allergy',
  'Food poisoning',
  'Foot drop',
  'Frontal lobe seizures',
  'Frontotemporal dementia',
  'Frostbite',
  'Frozen shoulder',
  "Fuchs' dystrophy",
  'Functional dyspepsia',
  'Functional neurologic disorders/conversion disorder',
  'Galactorrhea',
  'Gallbladder cancer',
  'Gallstones',
  'Ganglion cyst',
  'Gangrene',
  'Gas and gas pains',
  'Gastritis',
  'Gastroesophageal reflux disease (GERD)',
  'Gastrointestinal bleeding',
  'Gastrointestinal stromal tumor (GIST)',
  'Gastroparesis',
  'Gaucher disease',
  'Gender dysphoria',
  'Generalized anxiety disorder',
  'Genital herpes',
  'Genital warts',
  'Geographic tongue',
  'Germ cell tumors',
  'Gestational diabetes',
  'Giant cell arteritis',
  'Giardia infection (giardiasis)',
  "Gilbert's syndrome",
  'Gingivitis',
  'Glaucoma',
  'Glioblastoma',
  'Glioma',
  'Glomerulonephritis',
  'Goiter',
  "Golfer's elbow",
  'Gonorrhea',
  'Gout',
  'Grand mal seizure',
  'Granuloma annulare',
  'Granulomatosis with polyangiitis',
  "Graves' disease",
  'Greenstick fractures',
  'Group B strep disease',
  'Growing pains',
  'Growth plate fractures',
  'Guillain-Barre syndrome',
  'H1N1 flu (swine flu)',
  'Hair loss',
  'Hairy cell leukemia',
  'Hammertoe and mallet toe',
  'Hamstring injury',
  'Hand-foot-and-mouth disease',
  'Hangovers',
  'Hantavirus pulmonary syndrome',
  "Hashimoto's disease",
  'Hay fever',
  'Head and neck cancers',
  'Head lice',
  'Headaches',
  'Hearing loss',
  'Heart arrhythmia',
  'Heart attack',
  'Heart disease',
  'Heart failure',
  'Heart murmurs',
  'Heart palpitations',
  'Heart valve disease',
  'Heartburn',
  'Heat exhaustion',
  'Heat rash',
  'Heatstroke',
  'Helicobacter pylori (H. pylori) infection',
  'Hemangioma',
  'Hemifacial spasm',
  'Hemochromatosis',
  'Hemolytic uremic syndrome (HUS)',
  'Hemophilia',
  'Hemorrhoids',
  'Henoch-Schonlein purpura',
  'Hepatitis A',
  'Hepatitis B',
  'Hepatitis C',
  'Hepatocellular carcinoma',
  'Hepatopulmonary syndrome',
  'Hereditary hemorrhagic telangiectasia',
  'Herniated disk',
  'Hiatal hernia',
  'Hiccups',
  'Hidradenitis suppurativa',
  'High blood pressure (hypertension)',
  'High blood pressure in children',
  'High cholesterol',
  'Hilar cholangiocarcinoma',
  'Hip dysplasia',
  'Hip fracture',
  'Hip impingement',
  'Hip labral tear',
  "Hirschsprung's disease",
  'Hirsutism',
  'Histoplasmosis',
  'HIV/AIDS',
  'Hives and angioedema',
  'Hoarding disorder',
  "Hodgkin's lymphoma (Hodgkin's disease)",
  'Horner syndrome',
  'Hot flashes',
  'HPV infection',
  'Hunter syndrome',
  "Huntington's disease",
  'Hurthle cell cancer',
  'Hydrocele',
  'Hydrocephalus',
  'Hydronephrosis',
  'Hypercalcemia',
  'Hypereosinophilic syndrome',
  'Hyperglycemia in diabetes',
  'Hyperhidrosis',
  'Hyperoxaluria and oxalosis',
  'Hyperparathyroidism',
  'Hyperthyroidism (overactive thyroid)',
  'Hypertrophic cardiomyopathy',
  'Hypoglycemia',
  'Hyponatremia',
  'Hypoparathyroidism',
  'Hypopituitarism',
  'Hypoplastic left heart syndrome',
  'Hypospadias',
  'Hypothermia',
  'Hypothyroidism (underactive thyroid)',
  'Ice cream headaches',
  'Ichthyosis vulgaris',
  'Idiopathic hypersomnia',
  "IgA nephropathy (Berger's disease)",
  'Illness anxiety disorder',
  'Immune thrombocytopenia (ITP)',
  'Impacted wisdom teeth',
  'Impetigo',
  'Incompetent cervix',
  'Indigestion',
  'Infant jaundice',
  'Infant reflux',
  'Infectious diseases',
  'Infertility',
  'Inflammatory bowel disease (IBD)',
  'Inflammatory breast cancer',
  'Influenza (flu)',
  'Ingrown hair',
  'Ingrown toenails',
  'Inguinal hernia',
  'Inherited metabolic disorders',
  'Insomnia',
  'Intermittent explosive disorder',
  'Interstitial cystitis',
  'Interstitial lung disease',
  'Intestinal ischemia',
  'Intestinal obstruction',
  'Intracranial hematoma',
  'Intracranial venous malformations',
  'Intussusception',
  'Invasive lobular carcinoma',
  'Iritis',
  'Iron deficiency anemia',
  'Irritable bowel syndrome',
  'Ischemic colitis',
  'Itchy skin (pruritus)',
  'Jaw tumors and cysts',
  'Jellyfish stings',
  'Jet lag disorder',
  'Jock itch',
  'Juvenile fibromyalgia',
  'Juvenile idiopathic arthritis',
  "Kaposi's sarcoma",
  'Kawasaki disease',
  'Keratitis',
  'Keratoconus',
  'Keratosis pilaris',
  'Kidney cancer',
  'Kidney cysts',
  'Kidney infection',
  'Kidney stones',
  'Kleptomania',
  'Klinefelter syndrome',
  'Klippel-Trenaunay syndrome',
  'Knee bursitis',
  'Knee pain',
  'Krabbe disease',
  'Kyphosis',
  'Lactose intolerance',
  'Laryngitis',
  'Latex allergy',
  'Lazy eye (amblyopia)',
  'Lead poisoning',
  'Left ventricular hypertrophy',
  'Legg-Calve-Perthes disease',
  "Legionnaires' disease",
  'Leiomyosarcoma',
  'Leukemia',
  'Leukoplakia',
  'Lewy body dementia',
  'Lice',
  'Lichen nitidus',
  'Lichen planus',
  'Lichen sclerosus',
  'Limited scleroderma',
  'Lip cancer',
  'Lipoma',
  'Liposarcoma',
  'Listeria infection',
  'Liver cancer',
  'Liver disease',
  'Liver hemangioma',
  'Lobular carcinoma in situ (LCIS)',
  'Long QT syndrome',
  'Low blood pressure (hypotension)',
  'Low sex drive in women',
  'Low sperm count',
  'Lung cancer',
  'Lupus',
  'Lupus nephritis',
  'Lyme disease',
  'Lymphedema',
  'Lymphoma',
  'Lynch syndrome',
  'Malaria',
  'Male breast cancer',
  'Male hypogonadism',
  'Male infertility',
  'Malignant hyperthermia',
  'Malignant peripheral nerve sheath tumors',
  'Mammary duct ectasia',
  'Marfan syndrome',
  'Mastitis',
  'MCAD deficiency',
  'Measles',
  'Median arcuate ligament syndrome (MALS)',
  'Medication overuse headaches',
  'Medulloblastoma',
  'Melanoma',
  'Membranous nephropathy',
  "Meniere's disease",
  'Meningioma',
  'Meningitis',
  'Menopause',
  'Menorrhagia (heavy menstrual bleeding)',
  'Menstrual cramps',
  'Mental illness',
  'Meralgia paresthetica',
  'Merkel cell carcinoma',
  'Mesenteric ischemia',
  'Mesenteric lymphadenitis',
  'Mesothelioma',
  'Metabolic syndrome',
  'Metachromatic leukodystrophy',
  'Metatarsalgia',
  'Microcephaly',
  'Microscopic colitis',
  'Migraine',
  'Migraine with aura',
  'Mild cognitive impairment (MCI)',
  'Milia',
  'Milk allergy',
  'Miscarriage',
  'Mitral valve disease',
  'Mitral valve prolapse',
  'Mitral valve regurgitation',
  'Mitral valve stenosis',
  'Mittelschmerz',
  'Mixed connective tissue disease',
  'Molar pregnancy',
  'Mold allergy',
  'Moles',
  'Molluscum contagiosum',
  'Monoclonal gammopathy of undetermined significance (MGUS)',
  'Mononucleosis',
  'Mood disorders',
  'Morning sickness',
  'Morphea',
  "Morton's neuroma",
  'Mosquito bites',
  'Mouth cancer',
  'Movement disorders',
  'Moyamoya disease',
  'MRSA infection',
  'Multiple endocrine neoplasia, type 1 (MEN 1)',
  'Multiple myeloma',
  'Multiple sclerosis',
  'Multiple system atrophy (MSA)',
  'Multisystem inflammatory syndrome in children (MIS-C)',
  'Mumps',
  'Muscle cramp',
  'Muscle strains',
  'Muscular dystrophy',
  'Myasthenia gravis',
  'Myelodysplastic syndromes',
  'Myelofibrosis',
  'Myocardial ischemia',
  'Myocarditis',
  'Myoclonus',
  'Myofascial pain syndrome',
  'Myxofibrosarcoma',
  'Naegleria infection',
  'Nail fungus',
  'Narcissistic personality disorder',
  'Narcolepsy',
  'Nasal and paranasal tumors',
  'Nasal polyps',
  'Nasopharyngeal carcinoma',
  'Nearsightedness',
  'Neck pain',
  'Nephrogenic systemic fibrosis',
  'Nephrotic syndrome',
  'Neuroblastoma',
  'Neurodermatitis',
  'Neuroendocrine tumors',
  'Neurofibroma',
  'Neurofibromatosis',
  'Neuromyelitis optica',
  'Nickel allergy',
  'Nicotine dependence',
  'Niemann-Pick',
  'Nightmare disorder',
  'Nonalcoholic fatty liver disease',
  'Nonallergic rhinitis',
  "Non-Hodgkin's lymphoma",
  'Nonmelanoma skin cancer',
  'Noonan syndrome',
  'Norovirus infection',
  'Nose fracture',
  'Obesity',
  'Obsessive-compulsive disorder (OCD)',
  'Obstructive sleep apnea',
  'Occupational asthma',
  'Ocular rosacea',
  'Oligodendroglioma',
  'Oppositional defiant disorder (ODD)',
  'Optic neuritis',
  'Oral lichen planus',
  'Oral thrush',
  'Orchitis',
  'Orthostatic hypotension (postural hypotension)',
  'Osgood-Schlatter disease',
  'Osteoarthritis',
  'Osteochondritis dissecans',
  'Osteomalacia',
  'Osteomyelitis',
  'Osteoporosis',
  'Osteosarcoma',
  'Ovarian cancer',
  'Ovarian cysts',
  'Ovarian hyperstimulation syndrome',
  'Overactive bladder',
  "Paget's disease of bone",
  "Paget's disease of the breast",
  'Painful intercourse (dyspareunia)',
  'Pancreatic cancer',
  'Pancreatic cysts',
  'Pancreatic neuroendocrine tumors',
  'Pancreatitis',
  'Panic attacks and panic disorder',
  'Paraganglioma',
  'Paraneoplastic syndromes of the nervous system',
  "Parkinson's disease",
  'Parotid tumors',
  'Partial anomalous pulmonary venous return',
  'Parvovirus infection',
  'Patellar tendinitis',
  'Patellofemoral pain syndrome',
  'Patent ductus arteriosus (PDA)',
  'Patent foramen ovale',
  'Peanut allergy',
  'Pectus carinatum',
  'Pectus excavatum',
  'Pediatric brain tumors',
  'Pediatric obstructive sleep apnea',
  'Pediatric thrombocytopenia',
  'Pediatric white blood cell disorders',
  'Pelvic inflammatory disease (PID)',
  'Pelvic organ prolapse',
  'Pemphigus',
  'Penicillin allergy',
  'Peptic ulcer',
  'Pericardial effusion',
  'Pericarditis',
  'Perimenopause',
  'Periodontitis',
  'Peripheral artery disease (PAD)',
  'Peripheral nerve injuries',
  'Peripheral nerve tumors',
  'Peripheral neuropathy',
  'Peritonitis',
  'Persistent depressive disorder (dysthymia)',
  'Persistent post-concussive symptoms (Post-concussion syndrome)',
  'Personality disorders',
  'Pet allergy',
  "Peyronie's disease",
  'Phantom pain',
  'Phenylketonuria (PKU)',
  'Pheochromocytoma',
  'Pilonidal cyst',
  'Pinched nerve',
  'Pineoblastoma',
  'Pink eye (conjunctivitis)',
  'Pinworm infection',
  'Pituitary tumors',
  'Pityriasis rosea',
  'Placenta accreta',
  'Placenta previa',
  'Placental abruption',
  'Plague',
  'Plantar fasciitis',
  'Plantar warts',
  'Pleurisy',
  'Pneumonia',
  'Pneumonitis',
  'Pneumothorax',
  'POEMS syndrome',
  'Poison ivy rash',
  'Polio',
  'Polycystic kidney disease',
  'Polycystic ovary syndrome (PCOS)',
  'Polycythemia vera',
  'Polyhydramnios',
  'Polymorphous light eruption',
  'Polymyalgia rheumatica',
  'Polymyositis',
  'Popliteal artery aneurysm',
  'Popliteal artery entrapment syndrome',
  'Porphyria',
  'Posterior cortical atrophy',
  'Posterior cruciate ligament injury',
  'Posterior vaginal prolapse (rectocele)',
  'Postherpetic neuralgia',
  'Postpartum depression',
  'Postpartum preeclampsia',
  'Postpartum thyroiditis',
  'Post-polio syndrome',
  'Post-traumatic stress disorder (PTSD)',
  'Pouchitis',
  'Prader-Willi syndrome',
  'Precocious puberty',
  'Prediabetes',
  'Preeclampsia',
  'Premature birth',
  'Premature ejaculation',
  'Premature ventricular contractions (PVCs)',
  'Premenstrual syndrome (PMS)',
  'Presbyopia',
  'Prescription drug abuse',
  'Preterm labor',
  'Priapism',
  'Primary aldosteronism',
  'Primary biliary cholangitis',
  'Primary immunodeficiency',
  'Primary lateral sclerosis (PLS)',
  'Primary ovarian insufficiency',
  'Primary progressive aphasia',
  'Primary sclerosing cholangitis',
  'Proctitis',
  'Progeria',
  'Progressive supranuclear palsy',
  'Prolactinoma',
  'Prostate cancer',
  'Prostatitis',
  'Pseudobulbar affect',
  'Pseudocholinesterase deficiency',
  'Pseudogout',
  'Pseudomembranous colitis',
  'Pseudotumor cerebri',
  'Psoriasis',
  'Psoriatic arthritis',
  'Pubic lice (crabs)',
  'Pulmonary atresia',
  'Pulmonary atresia with intact ventricular septum',
  'Pulmonary atresia with ventricular septum defect',
  'Pulmonary edema',
  'Pulmonary embolism',
  'Pulmonary fibrosis',
  'Pulmonary hypertension',
  'Pulmonary valve disease',
  'Pulmonary valve stenosis',
  'Pyloric stenosis',
  'Pyoderma gangrenosum',
  'Q fever',
  'Rabies',
  'Radiation enteritis',
  'Radiation sickness',
  'Ramsay Hunt syndrome',
  "Raynaud's disease",
  'Reactive arthritis',
  'Reactive attachment disorder',
  'Rectal cancer',
  'Rectal prolapse',
  'Rectovaginal fistula',
  'Recurrent breast cancer',
  'REM sleep behavior disorder',
  'Renal artery stenosis',
  'Residual limb pain',
  'Respiratory syncytial virus (RSV)',
  'Restless legs syndrome',
  'Retinal detachment',
  'Retinal diseases',
  'Retinoblastoma',
  'Retractile testicle',
  'Retrograde ejaculation',
  'Rett syndrome',
  "Reye's syndrome",
  'Rhabdomyosarcoma',
  'Rheumatic fever',
  'Rheumatoid arthritis',
  'Rickets',
  'Ringworm (body)',
  'Ringworm (scalp)',
  'Rocky Mountain spotted fever',
  'Rosacea',
  'Roseola',
  'Rotator cuff injury',
  'Rotavirus',
  'Rubella',
  'Rumination syndrome',
  'Ruptured eardrum (perforated eardrum)',
  'Ruptured spleen',
  'Sacral dimple',
  'Sacroiliitis',
  'Salivary gland tumors',
  'Salmonella infection',
  'Sarcoidosis',
  'Sarcoma',
  'Scabies',
  'Scarlet fever',
  'Schizoaffective disorder',
  'Schizoid personality disorder',
  'Schizophrenia',
  'Schizotypal personality disorder',
  'Schwannoma',
  'Sciatica',
  'Scleroderma',
  'Sclerosing mesenteritis',
  'Scoliosis',
  'Scorpion sting',
  'Scrotal masses',
  'Seasonal affective disorder (SAD)',
  'Sebaceous carcinoma',
  'Seborrheic dermatitis',
  'Seborrheic keratosis',
  'Secondary hypertension',
  'Seizures',
  'Selective IgA deficiency',
  'Self-injury/cutting',
  'Separated shoulder',
  'Separation anxiety disorder',
  'Sepsis',
  'Septic arthritis',
  'Serotonin syndrome',
  'Severe acute respiratory syndrome (SARS)',
  'Sex headaches',
  'Sexually transmitted diseases (STDs)',
  'Shaken baby syndrome',
  "Sheehan's syndrome",
  'Shellfish allergy',
  'Shigella infection',
  'Shin splints',
  'Shingles',
  'Short bowel syndrome',
  'Sick sinus syndrome',
  'Sickle cell anemia',
  'Sinus headaches',
  "Sjogren's syndrome",
  'Skin cancer',
  'Sleep apnea',
  'Sleep disorders',
  'Sleep terrors (night terrors)',
  'Sleep-related eating disorder',
  'Sleepwalking',
  'Small bowel cancer',
  'Small bowel prolapse (enterocele)',
  'Small intestinal bacterial overgrowth (SIBO)',
  'Small vessel disease',
  'Smallpox',
  'Snoring',
  'Social anxiety disorder (social phobia)',
  'Soft palate cancer',
  'Soft tissue sarcoma',
  'Solitary fibrous tumor',
  'Solitary rectal ulcer syndrome',
  'Somatic symptom disorder',
  'Sore throat',
  'Soy allergy',
  'Specific phobias',
  'Spermatocele',
  'Spider bites',
  'Spina bifida',
  'Spinal arteriovenous malformation (AVM)',
  'Spinal cord injury',
  'Spinal cord tumor',
  'Spinal headaches',
  'Spinal stenosis',
  'Spontaneous coronary artery dissection (SCAD)',
  'Sprained ankle',
  'Sprains',
  'Squamous cell carcinoma of the skin',
  'Stage 4 prostate cancer',
  'Staph infections',
  'Stevens-Johnson syndrome',
  'Stickler syndrome',
  'Stomach cancer',
  'Stomach polyps',
  'Strep throat',
  'Stress fractures',
  'Stress incontinence',
  'Stretch marks',
  'Stroke',
  'Stuttering',
  'Sty',
  'Subarachnoid hemorrhage',
  'Subconjunctival hemorrhage (broken blood vessel in eye)',
  'Sudden cardiac arrest',
  'Sudden infant death syndrome (SIDS)',
  'Suicide and suicidal thoughts',
  'Sun allergy',
  'Sunburn',
  'Supraventricular tachycardia',
  'Suspicious breast lumps',
  'Sweating and body odor',
  "Sweet's syndrome",
  "Swimmer's ear",
  "Swimmer's itch",
  'Swollen knee',
  'Swollen lymph nodes',
  'Synovial sarcoma',
  'Syphilis',
  'Syringomyelia',
  'Systemic capillary leak syndrome',
  'Systemic mastocytosis',
  'Tachycardia',
  "Takayasu's arteritis",
  'Tapeworm infection',
  'Tay-Sachs disease',
  'Teen depression',
  'Temporal lobe seizure',
  'TEN',
  'Tendinitis',
  'Tennis elbow',
  'Tension headache',
  'Testicular cancer',
  'Testicular torsion',
  'Tetanus',
  'Tetralogy of Fallot',
  'Thalassemia',
  'Thoracic aortic aneurysm',
  'Thoracic outlet syndrome',
  'Throat cancer',
  'Thrombocytopenia (low platelet count)',
  'Thrombocytosis',
  'Thrombophlebitis',
  'Thumb arthritis',
  'Thunderclap headaches',
  'Thyroid cancer',
  'Thyroid nodules',
  'Tinea versicolor',
  'Tinnitus',
  'TMJ disorders',
  'Toe walking in children',
  'Tongue cancer',
  'Tongue-tie (ankyloglossia)',
  'Tonsil cancer',
  'Tonsillitis',
  'Tooth abscess',
  'Torn meniscus',
  'Total anomalous pulmonary venous return',
  'Tourette syndrome',
  'Toxic hepatitis',
  'Toxic shock syndrome',
  'Toxoplasmosis',
  'Trachoma',
  'Transient global amnesia',
  'Transient ischemic attack (TIA)',
  'Transposition of the great arteries',
  'Transverse myelitis',
  'Traumatic brain injury',
  "Traveler's diarrhea",
  'Trichinosis',
  'Trichomoniasis',
  'Trichotillomania (hair-pulling disorder)',
  'Tricuspid atresia',
  'Tricuspid valve disease',
  'Tricuspid valve regurgitation',
  'Trigeminal neuralgia',
  'Trigger finger',
  'Triple X syndrome',
  'Truncus arteriosus',
  'Tuberculosis',
  'Tuberous sclerosis',
  'Tularemia',
  'Turner syndrome',
  'Type 1 diabetes',
  'Type 1 diabetes in children',
  'Type 2 diabetes',
  'Type 2 diabetes in children',
  'Typhoid fever',
  'Ulcerative colitis',
  'Ulnar wrist pain',
  'Umbilical hernia',
  'Undescended testicle',
  'Undifferentiated pleomorphic sarcoma',
  'Ureteral cancer',
  'Ureteral obstruction',
  'Urethral stricture',
  'Urinary incontinence',
  'Urinary tract infection (UTI)',
  'Urine color',
  'Uterine fibroids',
  'Uterine polyps',
  'Uterine prolapse',
  'Uveitis',
  'Vaginal agenesis',
  'Vaginal atrophy',
  'Vaginal cancer',
  'Vaginal fistula',
  'Vaginitis',
  'Valley fever',
  'Varicocele',
  'Varicose veins',
  'Vascular dementia',
  'Vascular rings',
  'Vasculitis',
  'Vasovagal syncope',
  'Ventricular fibrillation (V-Fib)',
  'Ventricular septal defect (VSD)',
  'Ventricular tachycardia',
  'Vertebral tumor',
  'Vesicoureteral reflux',
  'Viral gastroenteritis (stomach flu)',
  'Viral hemorrhagic fevers',
  'Vitamin deficiency anemia',
  'Vitiligo',
  'Vocal cord paralysis',
  'Voice disorders',
  'Von Willebrand disease',
  'Vulvar cancer',
  'Vulvodynia',
  'Waldenstrom macroglobulinemia',
  'West Nile virus',
  'Wet macular degeneration',
  'Wheat allergy',
  'Whiplash',
  "Whipple's disease",
  'Whooping cough',
  "Wilms' tumor",
  "Wilson's disease",
  'Wolff-Parkinson-White (WPW) syndrome',
  'Wrinkles',
  'Wrist pain',
  'X-linked agammaglobulinemia',
  'Yeast infection (vaginal)',
  'Yellow fever',
  'Yips',
  'Zika virus',
  'Zollinger-Ellison syndrome',
  'Other - my conditions is not present',
  'None (Controls)',
]
